import { crawlablePages } from '../crawlablePages';

export const sitemap = () => {
    let output = `<div class="lightBlue"><p>Here is PAOLOMEOLA64 HTML sitemap:</p><ul>`;
    for (const page of crawlablePages) {
        output += `<li><a href="/?a=${page.alias}" target="_blank">${page.title}</a></li>`;
    }
    output += `</ul></div>`;

    return output;
}
