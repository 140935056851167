import axios from 'axios';

export const fact = async () => {
    const bannedFacts = [];
    let output = '<p>Here is a fact about Paolo Meola:</p>';

    try {
        let fact = await getFact();
        console.log(`FACT: ${fact.data.id}`);

        if (bannedFacts.indexOf(fact.data.id)!==-1) {
            console.log(fact.data.id);
            console.log('banned fact');
            output += `<p class="lightRed">No, wait! I had a fact in mind, but I just forgot it!  ¯\\_(ツ)_/¯</p>`;
            output += `<p class="lightRed">Would you like to try again? </p>`;
        } else {
            const pun = fact.data.value
                .replace(/Chuck/g, 'Paolo')
                .replace(/chuck/g, 'paolo')
                .replace(/Norris/g, 'Meola')
                .replace(/norris/g, 'meola')
                .replace(/\(CNC\)/g, '(PMC)')
                .replace(/1CRNhK/g, '1PMNhK');
            output += `<p class="lightBlue">${pun}</p>`
        }
    } catch (e) {
        console.log('ERROR!!');
        console.log(e);
        output += `<p class="lightRed">I am not in the mood for facts now :'(</p>`
    }

    return output;
}

const getFact = () => {
    const endpoint = "https://api.chucknorris.io/jokes/random?category=dev";
    return axios.get(endpoint);
}
