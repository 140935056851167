import axios from 'axios';

export const joke = async () => {
    const bannedJokes = [7];
    let output = '<p>Here is a funny joke:</p>';

    try {
        let joke = await getJoke();
        console.log(`JOKE: ${joke.data.id}`);
        if (bannedJokes.indexOf(joke.data.id)!==-1) {
            console.log('banned joke');
            output += `<p class="lightRed">No, wait! I had a joke in mind, but I just forgot it!  ¯\\_(ツ)_/¯</p>`;
            output += `<p class="lightRed">Would you like to try again? </p>`;
        } else if (joke.data.type === "single") {
            const pun = joke.data.joke.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br/>')
            output += `<p class="lightBlue">${pun}</p>`
        } else if (joke.data.type === "twopart") {
            const setup = joke.data.setup.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br/>')
            const delivery = joke.data.delivery.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br/>')
            output +=
                `<p class="lightBlue">- ${setup}</p>`+
                `<p class="lightGreen">- ${delivery}</p>`;
        } else {
            console.log('unexpected joke type');
            output += `<p class="lightRed">I am not in the mood for jokes now :'(</p>`
        }
    } catch (e) {
        console.log('ERROR!!');
        console.log(e);
        output += `<p class="lightRed">I am not in the mood for jokes now :'(</p>`
    }

    return output;
}

const getJoke = () => {
    const endpoint = "https://sv443.net/jokeapi/v2/joke/Programming,Miscellaneous?blacklistFlags=religious,political,racist,sexist";
    return axios.get(endpoint);
}
