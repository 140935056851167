export const help = () => {
    const commands = [
        {cmd: 'advise', help: `to receive advice from Paolo.`},
        {cmd: 'cls', help: `to clean the screen.`},
        {cmd: 'contacts', help: `to print Paolo's contact info.`},
        {cmd: 'encode', help: `to encode a message through PAOLOMEOLA64.`, usage: 'ENCODE &lt;MESSAGE&gt;'},
        {cmd: 'fact', help: `to print a fact about Paolo Meola.`},
        {cmd: 'help', help: `to show PAOLOMEOLA64 commands list.`},
        {cmd: 'history', help: `to show the command history.`},
        {cmd: 'intro', help: `to discover a little more about Paolo.`},
        {cmd: 'joke', help: `to print a nerdy joke.`},
        {cmd: 'sing', help: `to make PAOLOMEOLA64 sing a song.`},
        {cmd: 'sitemap', help: `to print PAOLOMEOLA64 HTML sitemap.`}
    ];
    let output = `<div class="lightBlue"><p>Here is a non-exhaustive list of the main commands of PAOLOMEOLA64:</p><ul>`;
    for (const command of commands) {

        output += `<li>`;
        output += `<span class="white">${command.cmd.toUpperCase()}</span>`;
        output += `<span class="lightGrey">${fillingDots(command.cmd)}</span>`;
        output += `<span>: ${command.help}</span>`;
        if (command.usage) {
            output += `<br/><span class="lightGrey">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;usage: </span><span class="lightGreen">${command.usage}</span>`;
        }
        output += `</li>`;
    }
    output += `</ul></div>`;

    return output;
}

const fillingDots = (cmd) => {
    let dots = '';
    for (let iii = 0; iii < 10 - cmd.length; iii++) {
        dots += '.';
    }
    return dots;
}
