<template>
  <div @click="focusOnTerminal">
    <h1 style="text-align: center">**** PAOLOMEOLA64 ****</h1>
    <img alt="Paolo" style="width: 320px; height: 320px" src="../assets/paolo.png">
    <br/>
    <br/>
    <p>I am Paolo Meola. Welcome to my personal website.</p>
    <br/>
    <p>READY.</p>
    <div id="history" v-html="printed"></div>
    <input
            id="terminal"
            v-model="command"
            v-bind:style="{width: command.length>0?command.length+'ch':'2px'}"
            v-bind:class="{hidden: loading}"
            @click="terminalCursorAtTheEnd"
            @keydown="keyPress"
            @focusout="focusOnTerminal"
            autofocus="autofocus"
    />
    <div id="loading" v-bind:class="{blinking:true, hidden: !loading}">...</div>
    <div id="caret" v-bind:class="{blinking:true, hidden: loading}">█</div>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        command: '',
        commandHistory: [''],
        commandHistoryIndex: 0,
        loading: false,
        printed: '',
        runCommandHistory: []
      }
    },
    methods: {
      keyPress: function (event) {
        if (event.key === "Enter") {
          this.runCommand();
        }
        if (event.key === "ArrowUp") {
          // up arrow was pressed
          if (this.commandHistoryIndex>0) {
            this.commandHistory[this.commandHistoryIndex] = this.command;
            this.commandHistoryIndex--;
            this.command = this.commandHistory[this.commandHistoryIndex];
          }
        }
        if (event.key === "ArrowDown") {
          // down arrow was pressed
          if (this.commandHistoryIndex<this.commandHistory.length-1) {
            this.commandHistory[this.commandHistoryIndex] = this.command;
            this.commandHistoryIndex++;
            this.command = this.commandHistory[this.commandHistoryIndex];
          }
        }
        this.terminalCursorAtTheEnd();
      },
      focusOnTerminal: function () {
        let terminal = document.getElementById('terminal');
        setTimeout(()=>{terminal.focus();}, 0);
      },
      ghostRun: function (command) {
        // ghostly type command in query parameter
        const typingInterval = 200;
        for (let charIndex = 0; charIndex < command.length; charIndex++) {
          const char = command[charIndex];
          setTimeout(
                  ()=>{
                    this.command+=char;
                    this.terminalCursorAtTheEnd();
                  }, typingInterval*(charIndex+1));
        }
        setTimeout(()=>{this.command = command; this.runCommand();}, typingInterval*(command.length+2));
      },
      runCommand: async function(command) {
        this.loading = true;
        if (!command) {
          command = this.command;
        }
        // update history
        this.runCommandHistory.push(command);

        this.commandHistory[this.commandHistory.length-1] = command;
        this.printed += `<p>${this.commandHistory[this.commandHistory.length-1]}</p><br/>`;
        this.command = '';
        this.commandHistory.push('');
        this.commandHistoryIndex = this.commandHistory.length-1;
        this.scrollToEnd();

        // run command
        this.printed += await runCommand(command);
        // run command in monitor

        const commandForMonitor = getBaseCommand(command);
        if (commandForMonitor === 'history') {
          this.printed += `<p class="lightBlue">Here below you can find the history of the commands you run:</p><ol start="0">`;
          for (const command of this.runCommandHistory) {
            this.printed += `<li class="white">${command}</li>`;
          }
          this.printed += `</ol>`;
        }
        this.printed += `<br/><br/><br/>`;
        if (commandForMonitor === 'sing') {
          this.scrollToEnd();
          await sleep(10500);
        }
        this.printed += `<p>READY:</p>`;
        if (commandForMonitor === 'cls') {
          this.printed = '';
        }

        this.scrollToEnd();
        this.loading = false;
        this.focusOnTerminal();
      },
      scrollToEnd: function () {
        let app = document.getElementById('app');
        setTimeout(()=>{app.scrollTo(0,app.scrollHeight);},0);
      },
      terminalCursorAtTheEnd: function () {
        let terminal = document.getElementById('terminal');
        setTimeout(()=>{
          terminal.selectionStart = terminal.selectionEnd = 10000;
          terminal.scrollLeft = terminal.scrollWidth;
        }, 0);
        this.scrollToEnd();
      }
    },
    mounted: function() {
      const parsed = queryString.parse(location.search);
      const CrawlerDetector = new Crawler.Crawler();

      if (parsed.a) {
        // change title, description and run command if find a valid alias
        const page = crawlablePages.find(page => parsed.a == page.alias);
        if (page) {
          let canonical = window.location.origin + '?a=' + page.alias;
          setMetaTags(page.title, page.description, canonical);
          this.runCommand(page.cmd);
        }
      } else if (parsed.cmd) {
        // change title and description if find page equivalent
        const baseCommand = getBaseCommand(parsed.cmd);
        const page = crawlablePages.find(page => baseCommand == page.cmd);
        if (page) {
          let canonical = window.location.origin + '?a=' + page.alias;
          setMetaTags(page.title, page.description, canonical);
          this.runCommand(page.cmd);
        }

        // run command
        if (parsed.ghost) {
          this.ghostRun(decodeURIComponent(parsed.cmd));
        } else {
          this.runCommand(decodeURIComponent(parsed.cmd));
        }
      } else if (parsed.msg) {
        try {
          setMetaTags('Someone has sent you a message through PAOLOMEOLA64!', 'Click on the link to discover the message PAOLOMEOLA64 has received for you!');
          const message = atob(parsed.msg)
          this.printed += `<br/><p>You received the following message/command through PAOLOMEOLA64:</p><br/>`;
          this.ghostRun(message);
        } catch(e) {
          this.runCommand('The message you received is corrupted!');
          console.log(e);
        }
      } else if (CrawlerDetector.isCrawler(navigator.userAgent)) {
        this.runCommand('sitemap');
      }
    },
  }

  import { runCommand, getBaseCommand } from './runCommand';
  import sleep from 'sleep-promise';
  import { crawlablePages } from "./crawlablePages";

  const Crawler  = require('es6-crawler-detect');
  const queryString = require('query-string');

  const setMetaTags = (title, description, canonical) => {

    if (title) {
      document.title = title;
      document.querySelector('meta[property="og:title"]').setAttribute('content', title);
    }

    if (description) {
      document.querySelector('meta[name="description"]').setAttribute('content', description);
      document.querySelector('meta[property="og:description"]').setAttribute('content', description);
    }

    if (canonical) {
      document.querySelector("link[rel='canonical']").setAttribute('href', canonical);
      document.querySelector('meta[property="og:url"]').setAttribute('content', canonical);
    }
  }
</script>

<style scoped>

</style>
