import {syntaxError} from './commands/sintaxError.js';
import {advise} from './commands/advise.js';
import {contacts} from './commands/contacts.js';
import {encode} from './commands/encode.js';
import {fact} from './commands/fact.js';
import {help} from './commands/help.js';
import {intro} from './commands/intro.js';
import {joke} from './commands/joke.js';
import {sing} from './commands/sing.js';
import {sitemap} from './commands/sitemap.js';
import {errorSound} from "./programs/musicPlayer/musicPlayer";

export const runCommand = async (command) => {
    const baseCommand = getBaseCommand(command);
    const parameters = getParameters(command);

    // commands
    switch (baseCommand) {
        case '':
            return `<p>You didn't type any command.</p><p>Please type a command and press ENTER.</p>`;
        case 'cls':
            // run in monitor
            return '';
        case 'advise':
            return advise();
        case 'contacts':
            return contacts();
        case 'encode':
            return encode(parameters);
        case 'fact':
            return fact();
        case 'help':
            return help();
        case 'history':
            // run in monitor
            return '';
        case 'intro':
            return intro();
        case 'joke':
            return joke();
        case 'sing':
            return sing();
        case 'sitemap':
            return sitemap();
        default:
            errorSound();
            return syntaxError();
    }
}

const alias = (command) => {
    if (['clean', 'clear'].indexOf(command)!==-1) command = 'cls';
    if (['facts'].indexOf(command)!==-1) command = 'fact';
    if (['ls', 'list', 'dir', 'commands'].indexOf(command)!==-1) command = 'help';
    if (['paolo'].indexOf(command)!==-1) command = 'intro';
    if (['jokes'].indexOf(command)!==-1) command = 'joke';
    if (['advice'].indexOf(command)!==-1) command = 'advise';
    if (['advisory'].indexOf(command)!==-1) command = 'advise';
    return command;
}

export const getBaseCommand = (command) => {
    const originalBaseCommand = command.indexOf(' ')>-1
        ?command.substr(0,command.indexOf(' '))
        :command;
    return alias(originalBaseCommand.toLowerCase());
}

export const getParameters = (command) => {
    return command.split(' ').length>1
        ?command.substr(command.indexOf(' ')+1)
        :'';
}

