export const crawlablePages = [
    {
        'cmd': `contacts`,
        'alias': `paolo-meola-s-contacts`,
        'title': `Paolo Meola's contacts`,
        'description': `Here you can find Paolo Meola's contacts.`
    },
    {
        'cmd': `fact`,
        'alias': `paolo-meola-s-facts`,
        'title': `Paolo Meola's facts`,
        'description': `Here you can find some very interesting fact about Paolo Meola and his adventurous life!`
    },
    {
        'cmd': `help`,
        'alias': `help-menu`,
        'title': `PAOLOMEOLA64 help menu`,
        'description': `Here you can find a useful list of commands you can use in PAOLOMEOLA64 terminal.`
    },
    {
        'cmd': `intro`,
        'alias': `paolo-meola-s-introduction`,
        'title': `Paolo Meola's introduction`,
        'description': `In this brief Paolo Meola's introduction, you can find some relevant piece of information about Paolo Meola.`
    },
    {
        'cmd': `joke`,
        'alias': `paolo-meola-s-jokes`,
        'title': `Paolo Meola's jokes`,
        'description': `Here you can read some joke which makes Paolo Meola laugh. They're a bit nerdy, but I hope you'll find them funny!`
    },
    {
        'cmd': `sing`,
        'alias': `paolo-meola-sings`,
        'title': `Paolo Meola sings`,
        'description': `Listen to Paolo Meola singing with his synthesized 8-bit voice!`
    },
    {
        'cmd': `advise`,
        'alias': `paolo-meola-s-advisory`,
        'title': `Paolo Meola's Advisory`,
        'description': `Receive advice from Paolo Meola!`
    }
];
