<template>
  <Monitor></Monitor>
</template>

<script>
import Monitor from './components/monitor.vue'

export default {
  name: 'App',
  components: {
    Monitor
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
  @import "./assets/theme.scss";
</style>
