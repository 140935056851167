// reference: https://www.andronio.me/2019/04/24/easily-play-a-song-track-in-javascript-using-tone-js-transport/
import { SimplePlayer } from './simplePlayer';
import { SequenceParser } from './sequenceParser';

export const playMusic = (tempoBpm, timeSignatureArray, textMeasures) => {
    const player = new SimplePlayer();
    const sequenceParser = new SequenceParser(tempoBpm, timeSignatureArray);
    player.play(sequenceParser.parse(textMeasures));
}

export const errorSound = () => {
    playMusic(115, [4,4], ['C4/32 C#3/32']);
}
