export const encode = (parameters) => {
    let output = '';
    if (parameters) {
        const origin = window.location.origin;
        const encodedMessage = btoa(parameters);
        const url = origin + '?msg=' + encodedMessage
        output += `<p class="lightBlue">You can send your message through the following link:</p>`;
        output += `<p><a href="${url}" target="_blank">${url}</a></p>`
    } else {
        output += `<span class="lightRed">ERROR</span>: <span class="lightBlue"><span>You have to add a message to encode after the encode command.</span>`;
    }
    return output;
}
