import { playMusic } from '../programs/musicPlayer/musicPlayer';

export const sing = async () => {
    const tempoBpm = 115;
    const timeSignatureArray = [4, 4];
    const textMeasures = [
        'C4/4 D4/4 E4/6 E4/4 D4/4 C4/6 B3/4 G3/4 B3/6 D4/4 E4/4 D4/6 C4/1 rest/8 E4/12 E4/12 F4/12 E4/8 C4/8 A3/8 F4/8 E4/10 C4/4'
    ];

    playMusic(tempoBpm, timeSignatureArray, textMeasures);

    let output =
        `<p class="lightBlue">PAOLOMEOLA64 is singing <span class="white">Amore Supersonico</span>.</p>` +
        `<p class="lightBlue">You can listen to it on <a href="https://www.youtube.com/watch?v=XLIA7F6Uxv8" target="_BLANK">YouTube</a> and <a href="https://open.spotify.com/track/0LrWtqhHkFfqGJEjFyLDZS" target="_BLANK">Spotify</a>.</p>`;

    return output;
}
